import { Badge, Box, Link, Text, useDisclosure } from '@chakra-ui/react'
import faker from '@faker-js/faker'
import { lessonTypesNames, Role, useAuthenticationStore, User } from '@heytutor/common'
import TableFilters, { filtersForRoute, FilterValues, fromQueryToFilters } from 'components/common/table-filters'
import DeleteButton from 'components/design-system/bits/delete-button'
import DuplicateButton from 'components/design-system/bits/duplicate-button'
import EditButton from 'components/design-system/bits/edit-button'
import StatsButton from 'components/design-system/bits/stats-button'
import Card from 'components/design-system/card'
import DuplicateLessonAlert from 'components/design-system/clone-lesson-alert'
import DeleteAlert from 'components/design-system/delete-alert'
import Layout from 'components/layouts/layout'
import { useLoading } from 'components/layouts/LoadingContext'
import Table, { getPageAfterAction, TableAction } from 'components/table'
import { LessonType, useDeleteLessonMutation, useDuplicateLessonMutation, useListLessonsQuery } from 'graphql/generated'
import { first, get, range } from 'lodash'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import styles from 'styles/table-title.module.scss'
import { getIsUsingNewLessonPathMode } from 'utils'
import { getPaginationIndex, setPagination } from 'utils/Pagination'
import { getRouteFilters } from 'utils/routeHelpers'
import routes from 'utils/routes'
import lessonStyles from './lessons.module.scss'

const loadingData = range(0, 15).map((id) => ({
  id,
  title: faker.lorem.words(2),
  description: faker.lorem.words(4),
  level: faker.lorem.words(1),
  subject: faker.lorem.words(1),
  type: faker.lorem.words(1)
}))

//next/link -> Link is for Client side navigation
export default function LessonList() {
  const pageSize = 15
  const router = useRouter()
  const loadingContext = useLoading()
  const user: User = useAuthenticationStore((state) => state.user)
  const [page, setPage] = useState(
    router.query.page ? parseInt(router.query.page.toString()) : getPaginationIndex(routes.lessons)
  )
  const paginationSearchVariables = { first: pageSize, page: page }
  const [searchVariables, setSearchVariables] = useState(paginationSearchVariables)
  const [{ data, error, fetching }, refetch] = useListLessonsQuery({
    variables: {
      ...searchVariables,
      ...paginationSearchVariables,
      excludeTypes: getIsUsingNewLessonPathMode()
        ? [LessonType.Diagnostic1, LessonType.Diagnostic2, LessonType.Diagnostic3]
        : []
    }
  })
  const [_1, deleteLesson] = useDeleteLessonMutation()
  const [_2, duplicateLesson] = useDuplicateLessonMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isOpenDuplicateAlert, setOpenDuplicateAlert] = useState<Boolean>()
  const [lessonToDelete, setLessonToDelete] = useState()
  const [lessonToDuplicate, setLessonToDuplicate] = useState()
  const isLoading = fetching || loadingContext.isLoading
  const currentPageCount = get(data, 'lessons.data.length', 0)
  const filters = getRouteFilters(router)
  const onEdit = ({ id }) => router.push(routes.editLesson(id))
  const adminActions = [
    {
      text: 'Edit',
      call: onEdit,
      Component: EditButton
    },
    {
      text: 'Stats',
      call: (values) => router.push(routes.viewStatsLesson(values.id)),
      Component: StatsButton
    },
    {
      text: 'Duplicate',
      call: (values) => onDuplicate(values.id),
      Component: DuplicateButton,
      disabled: (values) => !values.presentationId
    },
    {
      text: 'Delete',
      call: (values) => onDelete(values.id),
      Component: DeleteButton
    }
  ]

  const Title = ({ values }) => {
    return (
      <>
        <div>
          <Link as="button" className={styles.title} onClick={() => onEdit(values)}>
            <b className={lessonStyles.title}>{values.title}</b>
            {values.internalTitle && <span className={lessonStyles.internalTitle}>{values.internalTitle}</span>}
          </Link>
        </div>
        {getIsUsingNewLessonPathMode() ? (
          <></>
        ) : (
          <Badge colorScheme={'gray'}>
            <small>{lessonTypesNames[values.type]}</small>
          </Badge>
        )}
      </>
    )
  }

  const Published = ({ isPublished }) => {
    return <Badge colorScheme={isPublished == 'true' ? 'green' : 'red'}>{isPublished}</Badge>
  }

  const table = {
    columns: React.useMemo(
      () => [
        {
          header: 'Title',
          accessor: 'title',
          Cell: (props) => <Title values={props.row.original} />
        },
        {
          header: 'Description',
          accessor: 'description'
        },
        {
          header: 'Level',
          accessor: 'level'
        },
        {
          header: 'Subject',
          accessor: 'subject'
        },
        {
          header: 'Published',
          accessor: 'published',
          Cell: ({ cell: { value } }) => <Published isPublished={value} />
        }
      ],
      []
    ),
    styles: {
      actions: {
        width: '165px'
      }
    },
    size: 'sm',
    data:
      isLoading || error
        ? loadingData
        : data.lessons.data.map((x) => ({
            ...x,
            published: x.published.toString(),
            subject: x.subject.name,
            level: x.level.name
          })),
    actions: [...(user?.role == Role.ADMIN || user?.role == Role.CONTENT_ADMIN ? adminActions : [])]
  }

  const onConfirmDelete = async () => {
    loadingContext.withLoading(async () => {
      onClose()

      if (lessonToDelete) {
        await deleteLesson({
          id: lessonToDelete
        })
      }

      const pageAfterAction = getPageAfterAction(TableAction.Remove, page, pageSize, currentPageCount)

      if (page != pageAfterAction) {
        setPage(pageAfterAction)
      }

      await refetch()
    })
  }

  const onConfirmDuplicate = async () => {
    loadingContext.withLoading(async () => {
      setOpenDuplicateAlert(false)

      if (lessonToDuplicate) {
        await duplicateLesson({
          id: lessonToDuplicate
        })
      }

      const pageAfterAction = getPageAfterAction(TableAction.Remove, page, pageSize, currentPageCount)

      if (page != pageAfterAction) {
        setPage(pageAfterAction)
      }

      await refetch()
    })
  }

  const onCancelDelete = async () => {
    setLessonToDelete(null)
    onClose()
  }

  const onCancelDuplicate = async () => {
    setLessonToDelete(null)
    setOpenDuplicateAlert(false)
  }

  const onDelete = async (id) => {
    setLessonToDelete(id)
    onOpen()
  }

  const onDuplicate = async (id) => {
    setLessonToDuplicate(id)
    setOpenDuplicateAlert(true)
  }

  const onFiltersChange = async (filters) => {
    const newFilters = {
      ...filters,
      ...(filters.subjectId ? { subjectId: [filters.subjectId] } : {}),
      ...(filters.levelId ? { levelId: [filters.levelId] } : {})
    }
    const routerQuery = filtersForRoute(routes.lessons, router.query, newFilters)

    router.push(routerQuery, undefined, { shallow: true })
  }

  useEffect(() => {
    if (router.query.filters) {
      setPagination(routes.lessons, 1, filters)
      setSearchVariables(fromQueryToFilters(router.query))
    }
  }, [])

  const initialValues = {
    title: filters?.title || '',
    levelId: first(filters?.levelId || []) || '',
    subjectId: first(filters?.subjectId || []) || '',
    schoolId: first(filters?.schoolId || []) || '',
    metaTags: filters?.metaTags || []
  } as FilterValues

  return (
    <Layout>
      <Head>
        <title>HeyTutor - Lessons</title>
      </Head>
      <Box w="100%">
        <Text
          fontSize={'24px'}
          fontWeight={'700'}
          textColor={'#4F4F4F'}
          marginBottom={'10px'}
          sx={{
            cursor: 'pointer'
          }}
        >
          Lessons
        </Text>

        <TableFilters
          withTitle={true}
          withLevel={true}
          withSubject={true}
          withTags={true}
          onFiltersChange={onFiltersChange}
          initialValues={initialValues}
        />

        <Card>
          <Table
            table={table}
            initialPage={page}
            isLoading={isLoading || !!error || user == null}
            fetchData={(pageIndex) => {
              setPage(pageIndex)
            }}
            pageSize={pageSize}
            pageCount={isLoading || error ? 0 : data.lessons.paginatorInfo.lastPage}
            isPaginated={!isLoading}
            filters={searchVariables}
            path={'lessons'}
          />
        </Card>
      </Box>

      <DeleteAlert
        title="Delete Lesson"
        message="Are you sure? You can't undo this action afterwards."
        isOpen={isOpen}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />

      <DuplicateLessonAlert
        title="Duplicate Lesson"
        message="Do you want to duplicate this lesson?."
        isOpen={isOpenDuplicateAlert}
        onCancel={onCancelDuplicate}
        onConfirm={onConfirmDuplicate}
      />
    </Layout>
  )
}
