import { IconButton, Tooltip } from '@chakra-ui/react'
import React from 'react'
import CopyIcon from './copy-icon'

type DuplicateButtonProps = {
  size?: number
  onClick: () => void
  disabled?: boolean
}

const DuplicateButton = ({ onClick, size = 20, disabled, ...props }: DuplicateButtonProps) => {
  return (
    <Tooltip label="Copy" hasArrow>
      <IconButton
        size="sm"
        aria-label="Duplicate"
        colorScheme="trueGray"
        icon={<CopyIcon size={size} />}
        borderRadius="full"
        onClick={onClick}
        disabled={disabled}
        {...props}
      />
    </Tooltip>
  )
}

export default DuplicateButton
