import { IconButton } from '@chakra-ui/react'
import React from 'react'
import { MdQueryStats } from 'react-icons/md'

type StatsButtonProps = {
  size?: number
  onClick: () => void
  disabled?: boolean
}

const StatsButton = ({ onClick, size = 20, disabled, ...props }: StatsButtonProps) => {
  return (
    <IconButton
      size="sm"
      aria-label="Stats"
      sx={{
        bg: 'transparent',
        fontSize: { size }
      }}
      icon={<MdQueryStats />}
      borderRadius="full"
      onClick={onClick}
      disabled={disabled}
      {...props}
    />
  )
}

export default StatsButton
