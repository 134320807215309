import { IconButton } from '@chakra-ui/react'
import DeleteIcon from './delete-icon'

type DeleteButtonProps = {
  size?: number
  disabled?: boolean
  onClick: () => void
  className?: string
}

const DeleteButton = ({ onClick, size = 20, disabled, ...props }: DeleteButtonProps) => {
  return (
    <IconButton
      size="sm"
      aria-label="Delete"
      colorScheme="trueGray"
      icon={<DeleteIcon size={size} />}
      borderRadius="full"
      onClick={onClick}
      disabled={disabled}
      {...props}
    />
  )
}

export default DeleteButton
